
		//  ------------------------------------------
		//	Styling to override default TYPO3 styles
		//  ------------------------------------------

.frame-ruler-after {
	border-bottom: 1px solid $gray-20;
}

.frame-ruler-before {
	border-top: 1px solid $gray-20;
}

.ce-right .ce-intext .ce-gallery,
.ce-left .ce-intext .ce-gallery,
.ce-right .ce-intext .ce-nowrap .ce-gallery,
.ce-left .ce-intext .ce-nowrap .ce-gallery {
	padding-top: 4px;
	margin-bottom: 0px;
}

div.ce-textpic .ce-gallery .ce-textpic-image{
	margin-bottom: 10px;
}

div.ce-left .ce-intext .ce-gallery, div.ce-left .ce-intext .ce-nowrap .ce-gallery {
	margin-right: 10px;
}

.ce-textpic-above .ce-gallery {
	margin-bottom: 0px;
}


// WCF Status
// Ready for WCF but likely will not be used.

%text-base {
    font-family: $font-family-base;
    font-size: rem-calc($text-base);
    color: $color-text;
    // @include fluid-type(font-size, $primary-font--min-vw, $primary-font--max-vw, $text-base-mobile, $text-base);
    line-height: 1.64705882;
    @include tablet-large {
        line-height: $line-height-base;
    }
}

// WCF Status
// Ready for WCF but may be edited.

.headline a {
    &,
    &:focus,
    &:active,
    &:visited:hover,
    &:visited:focus,
    &:visited:active {
        color: $color-text;
        text-decoration: none;
    }
    
    &:hover {
        color: $color-primary;
    }
}

h1, h2, h3, h4,h5, h6 {
    @extend .headline-base;
}

.headline {
    &-base {
        margin-bottom: .4em;
        font-family: $headline-base-font-family;
        font-weight: 700;
        font-size: rem-calc($text-3xl);
        line-height: $headline-base-line-height;
        @include tablet {
            font-size: rem-calc($text-4xl);
        }
    }
    &-2xs  {
        @extend .headline-base;
        font-size: rem-calc($text-lg);
    }  
    &-xs  {
        @extend .headline-base;
        font-size: rem-calc($text-xl);
    }
    &-sm  {
        @extend .headline-base;
        font-size: rem-calc($text-2xl);
    }
    &-lg, &-microsite-2, &-default  {
        @extend .headline-base;
    }
    &-xl  {
        @extend .headline-base;
        font-size: rem-calc($text-5xl);
    }
    &-2xl, &-microsite-1  {
        @extend .headline-base;
        font-size: rem-calc(36);
        line-height: rem-calc(40);
        @include tablet {
            font-size: rem-calc(48);
            line-height: rem-calc(53);
        }
    }
    &-3xl  {
        @extend .headline-base;
        font-size: rem-calc($text-7xl);
    }
    &-4xl  {
        @extend .headline-base;
        font-size: rem-calc($text-8xl);
    }
    &-5xl  {
        @extend .headline-base;
        font-size: rem-calc($text-9xl);
    }
}



// .headline {
//     &--sm, &--small {
//         @extend .headline-base;
//         font-size: 30px;
//     }
// }

// .headline, .headline-medium {
//     @extend .headline-base;
// }

// .headline {
//     &--lg {
//         @extend .headline-base;
//         font-size: 48px;
//         line-height: 1.10416667;
//     }
// }

// .headline-xl {
//     @extend .headline-base;
//     font-size: 45px;
// }

// .headline-2xl {
//     @extend .headline-base;
//     font-size: 50px;
// }

// .headline-thin {
//     font-weight: normal;
// }

// .subtitle, .headline-subtitle {
//     @extend .headline-base;
//     font-weight: normal;
//     font-size: 25px;
//     &.font-italic {
//         font-style: italic;
//     }
//     // @TODO-RON Is this needed?
//     &.font-bold {
//         font-weight: bold;
//     }
// }

.bg-position {
    &--default {
        background-position: center center !important;
    }
    &--left-top {
        background-position: left top !important;
    }
    &--left-bottom {
        background-position: left bottom !important;
    }
    &--left-center {
        background-position: left center !important;
    }
    &--right-top {
        background-position: right top !important;
    }
    &--right-bottom {
        background-position: right bottom !important;
    }
    &--right-center {
        background-position: right center !important;
    }
    &--center-top {
        background-position: center top !important;
    }
    &--center-bottom {
        background-position: center bottom !important;
    }
    &--center-center {
        background-position: center center !important;
    }
}

// ======================================================

// BUSY NOGGIN UTILITY CLASSES

// ======================================================

// Borders
.top-rule {
    border-top: $border-base;
    padding-top: 30px;
}

.bottom-rule {
    border-bottom: $border-base;
    padding-bottom: 30px;
}

// Top and Bottom Rules


// CLEARFIX
.clearfix {
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after { clear: both; }
    *zoom : 1;
}

// NO BULLETS
.no-bullets {
    padding-left: 0;
    list-style: none;
}

// RATIOS

// 16:9 ratio
.bn-ratio {
    position: relative;

    &::before {
        display: block;
        width: 0;
        height: 0;
        content: "";
    }
    &--square::before {
        padding-bottom: 100%;
    }
    &--16x9::before {
        padding-bottom: 56.25%;
    }
    &--3x2::before {
        padding-bottom: 66.66667%;
    }
    &--4x3::before {
        padding-bottom: 75%;
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


// @TODO-RON Research purpose and decide if needed
.screen-reader-only{
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(1px 1px 1px 1px); // IE 6 and 7
    clip: rect(1px,1px,1px,1px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    overflow: hidden !important;
}









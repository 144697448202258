		//  =========================================
		//	DO NOT EDIT THIS FILE!!!!.
		//	Most of this code is only used when doing special responsive sites
		//	using columns. If needed override the styles in the skin.
		//  =========================================


/* @todo-ron MAY NEED WORK ON THIS FOR RESPONSIVE OR GENERATED PIXEL STYLES */

// @TODO-RON IS ANY OF THIS NEEDED NOW WITH UPGRADE

@function half-column-width($container-width, $gutter-width) {
	@return (((($container-width - (1*$gutter-width))/2))/$container-width)*100%;
}

@function third-column-width($container-width, $gutter-width) {
	@return (((($container-width - (2*$gutter-width))/3))/$container-width)*100%;
}

@function two-third-column-width($container-width, $gutter-width) {
	@return ((((($container-width - (2*$gutter-width))/3)*2) + ($gutter-width))/$container-width)*100%;
}

@function quarter-column-width($container-width, $gutter-width) {
	@return ((($container-width - (3*$gutter-width))/4)/$container-width)*100%;
}

@function three-quarter-column-width($container-width, $gutter-width) {
	@return ((((($container-width - (3*$gutter-width))/4)*3) + (2*$gutter-width))/$container-width)*100%;
}

.column-group.rule {
	border-bottom: $column-bottom-rule;
}

@mixin column-calculation ($container-width, $gutter-width) {
	$gutter-percent : item-width($gutter-width,$container-width);
	.column-group {
		.column {
			margin-right: $gutter-percent;
		}
		.half {
			width: half-column-width($container-width, $gutter-width);
		}
		.third {
			width: third-column-width($container-width, $gutter-width);
		}

		.two-third {
			width: two-third-column-width($container-width, $gutter-width);
		}
		.quarter {
			width: quarter-column-width($container-width, $gutter-width);
		}
		.three-quarter {
			width: three-quarter-column-width($container-width, $gutter-width);
		}
		&.two-column-group .column-2,
		&.three-column-group .column-3,
		&.four-column-group .column-4 {
			margin-right: 0;
		}
	}
}

// WCF Status
// Ready for WCF but may be edited.

ul, ol, dl, dd {
    margin-bottom: .5em;
}

// ol {
//     ::marker {
//         font-weight: 700;
//     }
// }


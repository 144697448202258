// .page {
//     &__wrap {
//         max-width: 1440px;
//         margin: 0 auto;
//         padding: 0 15px;
//     }
//     &__content {
//         &-outer-wrap {
//             display: flex;
//             padding: 35px 20px;
//         }
//         &-wrap {
//             flex-grow: 1;
//         }
//         &-main {
//             display: flex;
//         }
//         &-block {
//             &-2 {
//             min-width: 330px;
//             margin-left: 40px;
//             }
//         }
//     }
//     &__generated-content {
//         &-1 {
//             min-width: 295px;
//             margin-right: 20px;
//             // padding-left: 0;
//             padding-right: 30px;
//             border-right: $border-thin-light;
//         }
//         &-2 {
//             // min-width: 325px;
//             // margin-left: 30px;
//         }
//     }
// }



.page {
    &__content {
        & > :first-child {
            margin-top: 0;
            & > :first-child {
                margin-top: 0;
                & > :first-child {
                    margin-top: 0;
                }
            }
        }
        & > :last-child {
            margin-bottom: 0;
            & > :last-child {
                margin-bottom: 0;
                & > :last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &--interior-main {
        & .page__content {
            & > :first-child {
                margin-top: 0;
                & > :first-child {
                    margin-top: 0;
                    & > :first-child {
                        margin-top: 0;
                        & > :first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
            & > :last-child {
                margin-bottom: 0;
                & > :last-child {
                    margin-bottom: 0;
                    & > :last-child {
                        margin-bottom: 0;
                    }
                }
            }
            // & > :first-child {
            //     margin-top: 0;
            // }
            // & > :last-child {
            //     margin-bottom: 0;
            // }
            &-wrap {

            }
            &-outer-wrap {
                max-width: rem-calc(1280);
                max-width: rem-calc(1600);
                margin: 0 auto;
                padding: rem-calc(20) rem-calc(20) rem-calc(50);
                @include tablet-large {
                    display: flex;
                    padding: rem-calc(40) rem-calc(20) rem-calc(80);
                }
            }
            &-wrap {
                & > .left-sidebar-content--mobile {
                    margin: rem-calc(40px) 0 0;
                    border-top: $border-base;
                    border-top-color: $gray-lighter;
                }
                @include tablet-large {
                    flex-basis: 76%;
                    padding: 0 rem-calc(20) rem-calc(50);
                    & > .left-sidebar-content--mobile {
                        display: none;
                    }
                }
            }
            &-inner-wrap {
                @include desktop {
                    display: flex;
                    padding-bottom: rem-calc(80);
                }
            }
            &--side {
                &-left {
                    margin-bottom: rem-calc(50);
                    @include tablet-large {
                        flex-basis: 26%;
                        min-width: rem-calc(290);
                        // margin-bottom: rem-calc(50);
                        padding-right: rem-calc(20);
                        border-right: $border-base;
                        border-right-color: $gray-line-light;
                        // background-color: $gray-lightest;
                    }
                    & > .left-sidebar-content {
                        display: none;
                        @include tablet-large {
                            display: block;
                        }
                    }
                }
                &-right {
                    margin-bottom: rem-calc(50);
                    @include desktop {
                        max-width: rem-calc(255);
                        // min-width: rem-calc(290);
                        // margin-bottom: rem-calc(50);
                        padding-left: rem-calc(20);
                        // border-right: $border-base;
                        // border-right-color: $gray-line-light;
                        // background-color: $gray-lightest;
                    }
                }
            }
        }
    }
}


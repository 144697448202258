.text-images {

    // clearfix
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after { clear: both; }
    *zoom : 1;

    &__ {

        &body-text .headline{
            margin-top: 0;
        }

        &image-group-wrap {

            box-sizing: border-box;

            margin: 0 auto 10px;

            &--medium {
                width: 60%;
            }

            &--small {
                width: 30%;
            }

        }

        &image-intext {

            .text-images__image-group-wrap {

                width: 50%;

                &--medium {
                    width: 30%;
                }

                &--small {
                    width: 20%;
                }

            }

            &.text-images__image-left .text-images__image-group-wrap {
                float: left;
                margin: 0;
                padding: 0 40px 30px 0;
            }

            &.text-images__image-right .text-images__image-group-wrap  {
                float: right;
                margin: 0;
                padding: 0 0 30px 35px;
            }
        }

        &image-beside-text {

            &.text-images__image-left .text-images__body-text {
                margin: 0 0 0 50%;
            }

            &.text-images__image-left.text-images__image-size--medium .text-images__body-text {
                margin: 0 0 0 30%;
            }
            &.text-images__image-left.text-images__image-size--small .text-images__body-text {
                margin: 0 0 0 20%;
            }

            &.text-images__image-right .text-images__body-text  {
                margin: 0 50% 0 0;
            }

            &.text-images__image-right.text-images__image-size--medium .text-images__body-text  {
                margin: 0 30% 0 0;
            }

            &.text-images__image-right.text-images__image-size--small .text-images__body-text  {
                margin: 0 20% 0 0;
            }

        }

        &image {
            width: 100%;
        }

        &image-size--original {

            &.text-images__ {

                &image-center {

                    .text-images__image-group {
                        img {
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }

                &image-intext.text-images__image-in-text {
                    .text-images__image-group-wrap {
                        width: auto;
                    }
                }

                &image-intext.text-images__image-beside-text.text-images__image-right {
                    .text-images__image-group {
                        float: right;
                    }
                }
            }
        }

    }

}

		//  ------------------------------------------
		//	Styling for TYPO3 extensions
		//  ------------------------------------------


		//  ------------------------------------------
		//	Physicians App
		//  ------------------------------------------

.physician-app {
	h3 {
		color: $color-1;
		margin-bottom: 3px;
	}

	.dummy {
		border: solid 1px red !important;
		margin:-2px;
	}

	&.search {

		span {
			font-weight:bold;
		}

		.tabs {
			padding: 0 0 0 14px;
			border-bottom: $border-1a;
			margin-left: 0px;
			margin-top: 30px;
			margin-bottom: 20px;
			height: 35px;
			font-family: $font-2;

			li {
				// @include border-top-radius(5px);
				display: inline-block;
				list-style-type: none;
				padding: 8px 20px 6px;
				margin-right: 8px;
				background-color: $color-1b;
			}

			li.active {
				border-top: $border-1a;
				border-left: $border-1a;
				border-right: $border-1a;
				background-color: $page-background-color;
				color: $headline-font-color;
				padding-bottom: 7px;
			}
		}

		.search-wrap {
			border-bottom: $border-1b;
			padding-bottom: 20px;
			overflow: auto;
			width: 100%;

			form {
				padding: 3px 0 3px 26px;
				margin-right: 16px;
				float: left;

				input[type="submit"] {
					@extend .button-red;
					padding: 2px 20px 2px 10px;
					border: none;
					margin-left: 10px;
				}
			}
		}

		.results-wrap {
			padding-left: 10px;

			.search-meta {
				float: left;
				padding-top: 10px;
			}

			.pagination {
				float: right;
				color: #aaa;
				text-transform: uppercase;
				padding-top: 10px;
				margin-bottom: 5px;

				li {
					list-style-type: none;
					display: inline;
					a {
						color: #aaa;
						font-weight: 400;
					}
				}

				.current {
					color: $color-1;
				}
				.previous,
				.next {
					a {
						color: $color-1;
					}
				}
			}

			.results {
				&:before {
					content: ".";
					display: block;
					height: 0;
					clear: both;
					visibility: hidden;
				}

				margin: 0;
				list-style: none;

				h3 {
					color: $color-1;
					margin-bottom: 3px;
				}

				span {
					font-weight:bold;
				}

				li {

					@extend .clearfix;
					border-bottom: $border-1b;
					padding: 20px 0;

					img, .placeholder {
						width: 80px;
						padding: 2px;
						border: $border-3;
						display: block;
						margin-right: 10px;
						float: left;
					}


					.description {
						width: 260px;
						float: left;
						margin-right: 20px;

						h3 {
							font-family: $font-1;
							font-size: 14px;
							margin-bottom: 8px;
						}

						p {
							line-height: 17px;
						}
					}

					.location {
						width: 177px;
						float: left;
					}

					.button-red {
						float: right;
						display: block;
						margin: 0;
					}
				}
			}
		}
	}

	&.profile {
		.header {
			background: $color-1b;
			padding: 20px;
			margin-bottom: 30px;
			float: left;
			// @include border-top-right-radius(25px);
			@extend .clearfix;
			overflow: auto;

			span {
				font-weight:bold;
				line-height: 17px;
			}

			.description {
				float: left;
				width: 278px;
				padding-right: 20px;

				h3 {
					font-size: 19px;
					margin-bottom: 8px;
				}

				p {
					line-height: 16px;
				}
			}

			.media {
				float: right;

				a.video {
					position: relative;
					float: right;
					margin-left: 35px;
					margin-bottom: 0px !important;

					span {
						background: url('/typo3conf/ext/bronx_skin/Resources/Public/Images/button_play.png') no-repeat left 3px;
						color: $page-background-color;
						text-transform: uppercase;
						padding: 13px 0 13px 55px;
						position: relative;
						bottom: 30px;
						left: 155px;
						margin-left: -140px;
					}
				}

				img.photo {
					float: right;
					padding: 2px;
					border: $border-4;
				}
			}
		}

		.details {
			float: left;
			width: 440px;
			list-style: none;
			overflow: auto;

			li {

				border-bottom: $border-1;
				display: inline-block;
				padding: 8px 0;

				div:first-of-type {
					float: left;
					width: 140px;

					span {
						font-weight:bold;
					}
				}


				div:last-of-type {
					float: left;
					width: 300px;
				}
			}
		}

		.location {
			float: right;
			border-left: $border-1;
			padding: 10px 0 20px 20px;
			width: 215px;

			span {
				font-weight:bold;
			}

			iframe {
				margin-top: 20px;
			}
		}
	}
}

.physicians-side-list {
	margin: 0;
	list-style: none;
	font-size: 13px;
	font-weight: bold;
	line-height: 16px;

	li {
		margin-bottom: 9px;
	}

	a {
		color: $color-1;
	}
}

.fancybox-skin .fancybox-title {
	font-family: $font-1;
	font-size: 11px;
	font-weight: bold;
	font-style: italic;
	line-height: 12px;
	color: $color-1;
	margin-bottom: 5px;
	padding-top: 0;
}


		//  ------------------------------------------
		//	flexslider
		//  ------------------------------------------

.flexslider {
	border: none!important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0 0px 0px rgba(0,0,0,0) !important;
    -webkit-box-shadow: 0 0px 0px rgba(0,0,0,0) !important;
    -moz-box-shadow: 0 0px 0px rgba(0,0,0,0) !important;
    -o-box-shadow: 0 0px 0px rgba(0,0,0,0) !important;
    margin-bottom: 0 !important;
    background-color: #205287 !important;
    height: 400px;

    .flex-control-nav {
		top: 307px !important;
		text-align: left !important;
		left: 35px !important;
		width: auto !important;
		z-index: 10 !important;

	    li {
	        margin: 0 3px !important;

	        a {
			    background: none repeat scroll 0 0 rgba(96, 133, 170, 1) !important;
			    height: 9px !important;
			    width: 9px !important;

			    &.flex-active {
			        background: none repeat scroll 0 0 rgba(172, 47, 53, 1) !important;
			    }
			}
	    }
	}

	.flex-direction-nav {
		.flex-prev {
			top: 150px !important;
			left: 0 !important;
			width: 31px !important;
			height: 61px !important;
			background: url(/typo3conf/ext/bronx_skin/Resources/Public/Images/bg_direction_nav_prev.png) no-repeat 0 0 !important;
			font-size: 0;

			&:hover {
				opacity: 1 !important;
			}
		}
		.flex-next {
			top: 150px !important;
			right: 0 !important;
			width: 31px !important;
			height: 61px !important;
			background: url(/typo3conf/ext/bronx_skin/Resources/Public/Images/bg_direction_nav_next.png) no-repeat 0 0 !important;
			font-size: 0;

			&:hover {
				opacity: 1 !important;
			}
		}

		a.flex-next:before,
		a.flex-prev:before {
			content: '';
		}
	}

	.slide-text {
		padding: 30px 35px 15px;
		border-top: 1px solid #b42c33;
		@extend .clearfix;

		.discription {
			color: white;
			line-height: 1.4em;
		}

		.more-btn {
			float: right;
			width: 120px;
			margin-top: 10px;
			margin-left: 30px;
			height: 50px;

			a {
				// @include border-radius();
				font-family: $font-2;
				font-weight: 400;
				text-align: left;
				padding: 6px 20px;
				text-decoration: none;
				font-size: 13px;
				color: #ffffff;
				background: $color-2;
			}
		}
	}
}

.flexslider-interior {
	padding-bottom: 20px !important;
	// height: 236px;
	// this height needs to be calculated by the ratio

	.flexslider {
		background-color: white !important;
		height: auto;

		.slides {
			li {
				// @include border-top-right-radius(25px);
				overflow: hidden;
			}
		}

	    .flex-control-nav {
	    	top: auto  !important;
			bottom: -30px !important;
			text-align: center !important;
			left: 0 !important;
			width: 100%  !important;

		    li {
		        margin: 0 3px !important;

		        a {
				    background: none repeat scroll 0 0 rgba(96, 133, 170, 1) !important;
				    height: 9px !important;
				    width: 9px !important;

				    &.flex-active {
				        background: none repeat scroll 0 0 rgba(172, 47, 53, 1) !important;
				    }
				}
		    }
		}
		.flex-direction-nav {
			.flex-prev {
				top: 50% !important;
			}
			.flex-next {
				top: 50% !important;

			}

		}
	}
}


		//  ------------------------------------------
		//	Locations App
		//  ------------------------------------------
.location-main-title {
	float: left;
	margin-left: 15px;
}

.location-network-link {
	font-weight: bold;
	padding: 0 15px 0 0;
	color: #265685;
	background: url(/typo3conf/ext/bronx_skin/Resources/Public/Images/link_arrow_blue.png) no-repeat right 6px;
	float: right;
	margin-top: 10px;
}

.content-wrap {
	background: $color-1c;

	.filter-bar {
		padding: 15px 15px 15px 35px;
		background: url('/typo3conf/ext/bronx_skin/Resources/Public/Images/VIEW-icon.png') no-repeat left 3px top 13px;
		margin-left: -16px;

		ul {
			padding-left: 30px;

			li {
				display: inline;
				list-style-type: none;
				margin-right: 10px;


				a {
					font-family: $font-2;
					text-transform: uppercase;
					font-weight: bold;
					color: $color-1;

					&:hover {
						color: $color-1;
						background: white;
					}
				}
			}

			li.active a {
				background-color: $color-2;
				padding: 4px;
				color: $page-background-color;
			}
		}
	}

	.column-wrap {
		width: 100%;
		overflow: hidden;

		.column {
			width: 48%;
			float: left;
			margin-bottom: -99999px;
			padding-bottom: 99999px;

			.location-category {
				padding: 0 15px 45px 15px;
				clear: both;

				li {
					list-style-type: none;
					margin-left: -16px;
					clear: both;
				}
			}

			.legend-key {
				color: white;
				padding-top: 3px;
				margin-right: 5px;
				font-size: 90%;
				height: 24px;
				width: 24px;
				text-align: center;
				display: block;
				float: left;
				position: static;
			}

			.legend-description {
				float: left;
				width: 90%;
				margin-top: 4px;
				padding-bottom: 12px;
				line-height: 16px;

				.title {
					color: $color-1;
					font-weight: bold;
				}

				.photo {
					margin: 10px 0;
				}
			}

			.new {
				h3 {
					color: #faa41a;
				}

				.legend-key {
					background: url('/typo3conf/ext/bronx_skin/Resources/Public/Images/yellow_dot.png') no-repeat 1px 1px;
				}

				.photo {
					border: 2px solid #faa41a;
				}
			}

			.practices {
				h3 {
					color: #ff3e00;
				}

				.legend-key {
					background: url('/typo3conf/ext/bronx_skin/Resources/Public/Images/orange_dot.png') no-repeat 1px 1px;
				}
			}

			.mlk {
				h3 {
					color: #009ddc;
				}

				.legend-key {
					background: url('/typo3conf/ext/bronx_skin/Resources/Public/Images/blue_dot.png') no-repeat 1px 1px
				}
			}

			.mhs {
				h3 {
					color: #85C441;
				}

				.legend-key {
					background: url('/typo3conf/ext/bronx_skin/Resources/Public/Images/green_dot.png') no-repeat 1px 1px;
				}
			}

			.map {
				margin-bottom: 15px;
				position: relative;

				.legend-key {
					position: absolute;
					margin-top: -12px;
					margin-left: -12px;
				}
			}
		}
	}

}

//  ------------------------------------------
//	Locations App
//  ------------------------------------------

.bronx-donations {
	$form-width: 700px;
	$gutter: 20px;
	$input-offset: 11px;
	border-top: $border-2;
	border-bottom: $border-2;

	#submission-wrap {
		text-indent: -9999px;
	}

	h3 {
		color: $gray-dark;
		font-size: 1.4em;
	}

	h4 {
		color: $gray-dark;
		font-size: 1.15em;
		margin: 0 0 4px 0;
	}

	input[type=text], input[type=email] {
		width: 100%;
		height: 26px;
		padding-left: 6px;
		border: 1px solid #d2d2d2;
		border-radius: 3px;
	}

	select {
		// @include appearance(none);
		//border: 0;
		border: 1px solid #d2d2d2;
		//outline: 1px solid #CCC;
		background-color: white;
		height: 32px;
		width: 100%;
		border-radius: 3px;
		padding-left: 6px;
	}

	.select-field {
		position: relative;
		display: inline-block;
	}

	.select-field:before {
		content: "\25B2";
		position: absolute;
		top: 3px;
		right: 0;
		bottom: 0;
		font-size: 60%;
		padding: 0 5px;
	}

	.select-field:after {
		content: "\25BC";
		position: absolute;
		top: 12px;
		right: 0;
		bottom: 0;
		font-size: 60%;
		padding: 0 5px;
	}

	.row {
		clear: both;

		.column {
			float: left;
			margin: 0 $gutter 10px 0;
			width: $form-width - $input-offset;

			&.last {
				margin-right: 0;
			}
		}

		&.two-column-row {
			.column {
				width: 375px !important;
				margin-right: $gutter + $input-offset;
				&.last {
					width: 284px !important;
					margin-right: 0;
				}
				&.last-select {
					width: 293px !important;
					margin-right: 0;
				}
			}

			&.indent {
				.column {
					width: 375px - $gutter !important;
					&.last {
						width: 284px - $gutter !important;
					}
					&.last-select {
						width: 293px !important;
						margin-right: 0;
					}
				}
			}
		}

		&.indent {
			margin-left: $gutter;
			.column {
				width: $form-width - $input-offset - $gutter;
			}
		}
	}

	.form-section {
		margin-bottom: 35px;

		fieldset {
			margin-top: 15px;
			&:first-of-type {
				margin-top: 0;
			}

			&.donation {
				.amount-wrap {
					.column {
						width: 65px;
						&:nth-last-child(2) {
							margin: 0;
						}
						&.last {
							margin-top: -5px;
							width: 200px;
						}
					}
				}
			}

			&.credit-card {
				.cc-number-wrap {
					.column {
						width: 526px;
						&.last {
							width: 154px;
							margin-top: 3px;
						}
					}
				}

				.cc-security-wrap {
					.column {
						width: 162px;
						&.last {
							width: 33px;
							margin-top: 3px;
						}
					}
				}
			}

			&.dedication-details {
				> .row:last-child {
					.column {
						width: 180px;
						&.last {
							width: 320px;
						}
					}
				}
			}

		}
	}

	.submit-wrap {
		text-align: center;
		margin: 30px auto;
		button {
			background-color: $color-2;
			padding: 12px 50px;
			color: white;
			// @include border-radius(5px);
			height: 30px;
			height: 54px;
			border: 0;
			font-family: "ff-din-web", Helvetica, Arial, sans-serif;
			font-weight: 600;
			font-size: 16px;
			width: 360px;

			.icon {
				.spinner[style] {
					left: -10px !important;
					top: -8px !important;
				}
			}
		}
	}


	.payment-form-confirmation {
		.label {
			font-weight: bold;
		}
	}


}

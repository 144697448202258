// ======================================================================
// FONTS
// ======================================================================

@font-face {
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 400;
    src: url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_2_0.eot?#iefix') format('embedded-opentype'),
    url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_2_0.woff2') format('woff2'),
    url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_2_0.woff') format('woff'),
    url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_2_0.ttf') format('truetype');
}

@font-face {
    font-family: 'proxima-nova';
    font-style: italic;
    font-weight: 400;
    src: url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_3_0.eot');
    src: url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_3_0.eot?#iefix') format('embedded-opentype'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_3_0.woff2') format('woff2'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_3_0.woff') format('woff'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_3_0.ttf') format('truetype');
}

@font-face {
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 600;
    src: url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_4_0.eot');
    src: url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_4_0.eot?#iefix') format('embedded-opentype'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_4_0.woff2') format('woff2'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_4_0.woff') format('woff'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_4_0.ttf') format('truetype');
}

@font-face {
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 700;
    src: url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_0_0.eot');
    src: url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_0_0.eot?#iefix') format('embedded-opentype'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_0_0.woff2') format('woff2'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_0_0.woff') format('woff'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'proxima-nova';
    font-style: italic;
    font-weight: 700;
    src: url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_1_0.eot');
    src: url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_1_0.eot?#iefix') format('embedded-opentype'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_1_0.woff2') format('woff2'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_1_0.woff') format('woff'), url('/typo3conf/ext/bronx_skin/Resources/Public/Fonts/ProximaNova/2FEDD3_1_0.ttf') format('truetype');
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,400&display=swap');



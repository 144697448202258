		//  ------------------------------------------
		//	MAIN STYLESHEET
		//	In this file you should centralize your imports
		//	The stylesheet this file produces is at:
		//	../../../Public/Stylesheets/Screen.css
		//  ------------------------------------------



// BRONX LEGACY CSS
// ====================================================================


// @import "RTE.scss"; // Temporarily commented out
@import "../Base/_BaseVariables";
@import "../Base/_SiteVariables";
@import "../Base/_BaseFunctions";
@import "../Base/_BaseMixins";
@import "../Base/_ColumnsBase";
@import "../Base/_ModulesBase";
@import "../Base/_BaseTYPO3Overrides";
@import "../Partials/_SiteTYPO3Overrides";

// @import "../Partials/_Layout"; // Turned over to "../sass-partials/30-templates/00-assembled.scss";

@import "../Partials/_CustomStyles";
@import "../Partials/_Extensions";
@import "../Partials/_CustomContentElements";
@import "../Partials/_Responsive";
@import "../Partials/_Columns";
@import "../Partials/_TextImages";
@import "../Partials/_VideoPlyr";

/* @todo-ron clean up following */
// @import "../Partials/_HeaderFooterMenus";  // Turned over to "../sass-partials/30-templates/00-assembled.scss"; —-- 20-page-header & 30-page-footer
// @import "../Partials/_MediaQueries";  // Turned over to "../sass-partials/00-config/30-mixins/10-media-queries.scss";


// BRONX UPGRADE 2022 CSS
// ====================================================================
@import "../sass-partials/00-config/00-assembled.scss";
// @import "../sass-partials/60-vendor/00-assembled.scss";
@import "../sass-partials/10-base-styling/00-assembled";
@import "../sass-partials/30-templates/00-assembled.scss";



// ALIGN TEXT

.text {
    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }
    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }

    &-bold {
        font-weight: bold;
    }

    &-italic {
        font-style: italic;
    }
}

.font {
    &-normal {
        font-weight: normal;
    }
    &-bold {
        font-weight: bold;
    }
    &-italic {
     font-weight: bold;
    }
}

.text-invert, .text-white { 
    color: white;
}

// GRAYs
.text {
    &-gray {
        &-light {
            color: $gray-light;
        }
        &-lighter {
            color: $gray-lighter;
        }
        &-lightest {
            color: $gray-lightest;
        }
        &-gray {
            color: $gray;
        }
        &-dark {
            color: $gray-dark;
        }
        &-darker {
            color: $gray-darker;
        }
        &-darkest {
            color: $gray-darkest;
        }

        &-05 {
            color: $gray-05;
        }
        &-10 {
            color: $gray-10;
        }
        &-15 {
            color: $gray-15;
        }
        &-20 {
            color: $gray-20;
        }
        &-25 {
            color: $gray-25;
        }
        &-30 {
            color: $gray-30;
        }
        &-35 {
            color: $gray-35;
        }
        &-40 {
            color: $gray-40;
        }
        &-45 {
            color: $gray-45;
        }
        &-50 {
            color: $gray-50;
        }
        &-55 {
            color: $gray-55;
        }
        &-60 {
            color: $gray-60;
        }
        &-65 {
            color: $gray-65;
        }
        &-70 {
            color: $gray-70;
        }
        &-75 {
            color: $gray-75;
        }
        &-80 {
            color: $gray-80;
        }
        &-85 {
            color: $gray-85;
        }
        &-90 {
            color: $gray-90;
        }
        &-95 {
            color: $gray-95;
        }
    }
}

		//  ------------------------------------------
		//	Custom styles for the skin
		//  ------------------------------------------


.rounded-corner {
	&.top.right {
		// @include border-top-right-radius(25px);
		overflow: hidden;
	}
}

.fancybox-title-float-wrap {
	bottom: -40px;
}

		//  ------------------------------------------
		//	home promotion teaser
		//  ------------------------------------------


.home-promo {
	margin:0 0 20px 0;
	padding: 0;
	list-style: none;
	text-align: center;

	.home-promo-text {
		padding: 0 10px;
		text-align: left;

		h3 {
			margin-top: 10px;
			padding-bottom: 0;
			border-bottom: none;
			font-size: 15px;
			font-weight: 600;
			line-height: 1.3em;

			a {
				font-weight: 600;
			}
		}

		p {
			margin-bottom: 0.2em;
			line-height: 1.4em;
			font-size: 13px;
		}

		.link {
			text-transform: uppercase;
			font-size: 11px;
			line-height: 17px;
			font-weight: bold;
			padding: 0 15px 0 0;
			color: #b42c33;
			background: url(/typo3conf/ext/bronx_skin/Resources/Public/Images/link_arrow.png) no-repeat right 2px;
		}
	}
}

.promo-toggle-btn {
	clear: both;
	text-align: right;
	padding: 20px 0;
	text-decoration: none;
	font-size: 13px;
	color: #ffffff;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 12px;
	background: url(/typo3conf/ext/bronx_skin/Resources/Public/Images/promo-accordion-btn-more.png) left center no-repeat;

	span {
		cursor: pointer;
		padding: 6px 25px 4px;
		font-family: $font-2;
		font-weight: normal;
		font-size: 11px;
		letter-spacing: 1px;
		display: inline-block;
			*display: inline;
			zoom: 1;
	}

}

.promo-toggle-btn-less {
	background: url(/typo3conf/ext/bronx_skin/Resources/Public/Images/promo-accordion-btn-less.png) left center no-repeat;


}

.promo-accordion-wrap {
	display: none;
	width: 100%;
}

		//  ------------------------------------------
		//	Find Physician
		//  ------------------------------------------
#find-physician, .physician-app.search .search-wrap {
	h2 {
		font-size: 16px;
		color: #FFFFFF;

		background-color: #8aa7c2;
		font-weight: 700;
		height: 25px;
		vertical-align: bottom;
		text-align: left;
		margin: 0px;
		padding: 4px 7px 0;

	}
	h3 {
		margin: 5px 0 0 0;
		padding: 4px 7px;
		cursor: pointer;
		font-size: 13px;
		font-weight: bold;
		color: #b42c33;
		border-left: 1px solid #b8cada;
		border-right: 1px solid #b8cada;
		background-color: #C4D3E0;

		&.first {
			margin-top: 0;
		}
	}
	.content {

		background-color: #C4D3E0;
		padding: 5px 7px;
		border: 1px solid #b8cada;
		border-top: none;
		@extend .clearfix;
		height: 105px;

		.button-red {
			// @include border-radius(3px);
			text-align: left;
			font-family: $font-2;
			font-weight: 400;
			padding: 4px 18px;
			text-decoration: none;
			font-size: 13px;
			margin-bottom: 10px;
			color: #ffffff;
			// @include box-shadow(3px 1px 3px rgba(0,0,0,.25));
			background: $color-2;
			border:none;
			float: right;
			margin-top: 15px;
			display: inline-block;
			*display: inline;
			zoom: 1;
		}
	}

	#Specialty {
		font-size: 12px;
		padding: 4px;
		width: 175px;
	}
	.roundedInput {
		height: 12px;
		padding: 6px 10px;
		width: 175px;
		// @include border-radius();
		font-size: 12px !important;
	}

	.hasCustomSelect {
		-webkit-appearance: menulist-button;
		width: 200px;
		vertical-align: middle;
		height: 30px;
		font-size: 12px;
	}

	#FirstName {
		margin-top: 5px;
	}
}

a.my-bronx-care-img {
	background: url(/typo3conf/ext/bronx_skin/Resources/Public/Images/my-bronx-care-image.jpg) 0 0 no-repeat;
	height: 76px;
	display: block;

	&:hover {
		background-position: 0 -76px ;
	}
}

.ce-headline-right {
    text-align: right;
}

.ce-headline-left {
    text-align: left;
}


.ce-headline-center {
    text-indent: 0;
    text-align: center;
}





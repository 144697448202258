//  ============================================================================
//
//  MEDIA QUERY MIXIN COMMONLY USED BY BUSY NOGGIN
//    https://dev.to/nidhishs/5-scss-mixins-to-check-out-2bkn#media-queries
//    Examples: 
        /*
            .foo-bar {
                width: 50%;
                @include media($from: "tablet-small", $until: 900px, $and:"orientation: landscape"){
                    width: 100%;
                }
            }
        */
        
        //  Nest mixins for common breakpoints
        //  Some are set up after the base mixin
        /*
            @mixin phone-large-until-desktop {
                @include media($from: "phone-large", $until: "desktop"){
                    @content
                }
            }
        */

// Breakpoints are set in variables file
// $breakpoints: (
//     zero: $zero,
//     phone: $phone,
//     phone-large: $phone-large,
//     phone-xlarge: $phone-xlarge,
//     tablet-small: $tablet-small,
//     tablet: $tablet, 
//     tablet-large: $tablet-large,
//     desktop: $desktop,
//     desktop-large: $desktop-large,
//     desktop-full: $desktop-full,
// );


// EXAMPLES
// @include media($tablet) {
// @include media($tablet-small) {
// @include media($from:"phone-large") {


//
//  ============================================================================

@mixin media(
    $from: false,
    $until: false,
    $and: false,
    $media-type: all,
) {
    $min-width: 0;
    $max-width: 0;
    $query: "";

    //FROM: this breakpoint (inclusive)
    @if $from {
        @if type-of($from) == number {
            $min-width: $from;
        } @else {
            $min-width: map-get($breakpoints, $from);
        }
    }

    //UNTIL: this breakpoint (exclusive)
    @if $until {
        @if type-of($until) == number {
            $max-width: $until - 1px;
        } @else {
            $max-width: map-get($breakpoints, $until) - 1px;
        }
    }

    @if $min-width != 0 {
        $query: "#{$query} and (min-width: #{$min-width})";
    }
    @if $max-width != 0 {
        $query: "#{$query} and (max-width: #{$max-width})";
    }
    @if $and {
        $query: "#{$query} and (#{$and})";
    }

    @if ($media-type == "all" and $query != "") {
        $media-type: "";
        $query: str-slice(unquote($query), 6);
    }

    @media #{$media-type + $query} {
        @content;
    }
}

// Mixins for common breakpoints
@mixin phone {
    @include media($from: "phone"){
        @content
    }
}

@mixin phone-large {
    @include media($from: "phone-large"){
        @content
    }
}

@mixin phone-xlarge {
    @include media($from: "phone-xlarge"){
        @content
    }
}

@mixin tablet-small {
    @include media($from: "tablet-small"){
        @content
    }
}

@mixin tablet {
    @include media($from: "tablet"){
        @content
    }
}

@mixin tablet-large {
    @include media($from: "tablet-large"){
        @content
    }
}

@mixin desktop {
    @include media($from: "desktop"){
        @content
    }
}

@mixin desktop-large {
    @include media($from: "desktop-large"){
        @content
    }
}

@mixin desktop-full {
    @include media($from: "desktop-full"){
        @content
    }
}
// MARGIN UTILITY CLASSES
// ====================================================================
.m {
    &-0 {
        margin: 0px !important;
    }
    &-20 {
        margin: rem-calc(10) !important;
        @include tablet-large {
            margin: rem-calc(20) !important;
        }
    }
    &-40 {
        margin: rem-calc(20) !important;
        @include tablet-large {
            margin: rem-calc(40) !important;
        }
    }
    &-60 {
        margin: rem-calc(30) !important;
        @include tablet-large {
            margin: rem-calc(60) !important;
        }
    }
    &-80 {
        margin: rem-calc(40) !important;
        @include tablet-large {
            margin: rem-calc(80) !important;
        }
    }
    &-100 {
        margin: rem-calc(50) !important;
        @include tablet-large {
            margin: rem-calc(100) !important;
        }
    }
    &-120 {
        margin: rem-calc(60) !important;
        @include tablet-large {
            margin: rem-calc(120) !important;
        }
    }
    &-140 {
        margin: rem-calc(70) !important;
        @include tablet-large {
            margin: rem-calc(140) !important;
        }
    }
    &-160 {
        margin: rem-calc(80) !important;
        @include tablet-large {
            margin: rem-calc(160) !important;
        }
    }
    &-180 {
        margin: rem-calc(90) !important;
        @include tablet-large {
            margin: rem-calc(180) !important;
        }
    }
    &-200 {
        margin: rem-calc(100) !important;
        @include tablet-large {
            margin: rem-calc(200) !important;
        }
    }
}
.mt {
    &-0 {
        margin-top: 0px !important;
    }
    &-20 {
        margin-top: rem-calc(10) !important;
        @include tablet-large {
            margin-top: rem-calc(20) !important;
        }
    }
    &-40 {
        margin-top: rem-calc(20) !important;
        @include tablet-large {
            margin-top: rem-calc(40) !important;
        }
    }
    &-60 {
        margin-top: rem-calc(30) !important;
        @include tablet-large {
            margin-top: rem-calc(60) !important;
        }
    }
    &-80 {
        margin-top: rem-calc(40) !important;
        @include tablet-large {
            margin-top: rem-calc(80) !important;
        }
    }
    &-100 {
        margin-top: rem-calc(50) !important;
        @include tablet-large {
            margin-top: rem-calc(100) !important;
        }
    }
    &-120 {
        margin-top: rem-calc(60) !important;
        @include tablet-large {
            margin-top: rem-calc(120) !important;
        }
    }
    &-140 {
        margin-top: rem-calc(70) !important;
        @include tablet-large {
            margin-top: rem-calc(140) !important;
        }
    }
    &-160 {
        margin-top: rem-calc(80) !important;
        @include tablet-large {
            margin-top: rem-calc(160) !important;
        }
    }
    &-180 {
        margin-top: rem-calc(90) !important;
        @include tablet-large {
            margin-top: rem-calc(180) !important;
        }
    }
    &-200 {
        margin-top: rem-calc(100) !important;
        @include tablet-large {
            margin-top: rem-calc(200) !important;
        }
    }
}
.mb {
    &-0 {
        margin-bottom: 0px !important;
    }
    &-20 {
        margin-bottom: rem-calc(10) !important;
        @include tablet-large {
            margin-bottom: rem-calc(20) !important;
        }
    }
    &-40 {
        margin-bottom: rem-calc(20) !important;
        @include tablet-large {
            margin-bottom: rem-calc(40) !important;
        }
    }
    &-60 {
        margin-bottom: rem-calc(30) !important;
        @include tablet-large {
            margin-bottom: rem-calc(60) !important;
        }
    }
    &-80 {
        margin-bottom: rem-calc(40) !important;
        @include tablet-large {
            margin-bottom: rem-calc(80) !important;
        }
    }
    &-100 {
        margin-bottom: rem-calc(50) !important;
        @include tablet-large {
            margin-bottom: rem-calc(100) !important;
        }
    }
    &-120 {
        margin-bottom: rem-calc(60) !important;
        @include tablet-large {
            margin-bottom: rem-calc(120) !important;
        }
    }
    &-140 {
        margin-bottom: rem-calc(70) !important;
        @include tablet-large {
            margin-bottom: rem-calc(140) !important;
        }
    }
    &-160 {
        margin-bottom: rem-calc(80) !important;
        @include tablet-large {
            margin-bottom: rem-calc(160) !important;
        }
    }
    &-180 {
        margin-bottom: rem-calc(90) !important;
        @include tablet-large {
            margin-bottom: rem-calc(180) !important;
        }
    }
    &-200 {
        margin-bottom: rem-calc(100) !important;
        @include tablet-large {
            margin-bottom: rem-calc(200) !important;
        }
    }
}
.ml {
    &-0 {
        margin-left: 0px !important;
    }
    &-20 {
        margin-left: rem-calc(10) !important;
        @include tablet-large {
            margin-left: rem-calc(20) !important;
        }
    }
    &-40 {
        margin-left: rem-calc(20) !important;
        @include tablet-large {
            margin-left: rem-calc(40) !important;
        }
    }
    &-60 {
        margin-left: rem-calc(30) !important;
        @include tablet-large {
            margin-left: rem-calc(60) !important;
        }
    }
    &-80 {
        margin-left: rem-calc(40) !important;
        @include tablet-large {
            margin-left: rem-calc(80) !important;
        }
    }
    &-100 {
        margin-left: rem-calc(50) !important;
        @include tablet-large {
            margin-left: rem-calc(100) !important;
        }
    }
    &-120 {
        margin-left: rem-calc(60) !important;
        @include tablet-large {
            margin-left: rem-calc(120) !important;
        }
    }
    &-140 {
        margin-left: rem-calc(70) !important;
        @include tablet-large {
            margin-left: rem-calc(140) !important;
        }
    }
    &-160 {
        margin-left: rem-calc(80) !important;
        @include tablet-large {
            margin-left: rem-calc(160) !important;
        }
    }
    &-180 {
        margin-left: rem-calc(90) !important;
        @include tablet-large {
            margin-left: rem-calc(180) !important;
        }
    }
    &-200 {
        margin-left: rem-calc(100) !important;
        @include tablet-large {
            margin-left: rem-calc(200) !important;
        }
    }
}
.mr {
    &-0 {
        margin-right: 0px !important;
    }
    &-20 {
        margin-right: rem-calc(10) !important;
        @include tablet-large {
            margin-right: rem-calc(20) !important;
        }
    }
    &-40 {
        margin-right: rem-calc(20) !important;
        @include tablet-large {
            margin-right: rem-calc(40) !important;
        }
    }
    &-60 {
        margin-right: rem-calc(30) !important;
        @include tablet-large {
            margin-right: rem-calc(60) !important;
        }
    }
    &-80 {
        margin-right: rem-calc(40) !important;
        @include tablet-large {
            margin-right: rem-calc(80) !important;
        }
    }
    &-100 {
        margin-right: rem-calc(50) !important;
        @include tablet-large {
            margin-right: rem-calc(100) !important;
        }
    }
    &-120 {
        margin-right: rem-calc(60) !important;
        @include tablet-large {
            margin-right: rem-calc(120) !important;
        }
    }
    &-140 {
        margin-right: rem-calc(70) !important;
        @include tablet-large {
            margin-right: rem-calc(140) !important;
        }
    }
    &-160 {
        margin-right: rem-calc(80) !important;
        @include tablet-large {
            margin-right: rem-calc(160) !important;
        }
    }
    &-180 {
        margin-right: rem-calc(90) !important;
        @include tablet-large {
            margin-right: rem-calc(180) !important;
        }
    }
    &-200 {
        margin-right: rem-calc(100) !important;
        @include tablet-large {
            margin-right: rem-calc(200) !important;
        }
    }
}


// PADDING UTILITY CLASSES
// ====================================================================
.p {
    &-0 {
        margin: 0px !important;
    }
    &-20 {
        margin: rem-calc(10) !important;
        @include tablet-large {
            margin: rem-calc(20) !important;
        }
    }
    &-40 {
        margin: rem-calc(20) !important;
        @include tablet-large {
            margin: rem-calc(40) !important;
        }
    }
    &-60 {
        margin: rem-calc(30) !important;
        @include tablet-large {
            margin: rem-calc(60) !important;
        }
    }
    &-80 {
        margin: rem-calc(40) !important;
        @include tablet-large {
            margin: rem-calc(80) !important;
        }
    }
    &-100 {
        margin: rem-calc(50) !important;
        @include tablet-large {
            margin: rem-calc(100) !important;
        }
    }
    &-120 {
        margin: rem-calc(60) !important;
        @include tablet-large {
            margin: rem-calc(120) !important;
        }
    }
    &-140 {
        margin: rem-calc(70) !important;
        @include tablet-large {
            margin: rem-calc(140) !important;
        }
    }
    &-160 {
        margin: rem-calc(80) !important;
        @include tablet-large {
            margin: rem-calc(160) !important;
        }
    }
    &-180 {
        margin: rem-calc(90) !important;
        @include tablet-large {
            margin: rem-calc(180) !important;
        }
    }
    &-200 {
        margin: rem-calc(100) !important;
        @include tablet-large {
            margin: rem-calc(200) !important;
        }
    }
}
.pt {
    &-0 {
        margin-top: 0px !important;
    }
    &-20 {
        margin-top: rem-calc(10) !important;
        @include tablet-large {
            margin-top: rem-calc(20) !important;
        }
    }
    &-40 {
        margin-top: rem-calc(20) !important;
        @include tablet-large {
            margin-top: rem-calc(40) !important;
        }
    }
    &-60 {
        margin-top: rem-calc(30) !important;
        @include tablet-large {
            margin-top: rem-calc(60) !important;
        }
    }
    &-80 {
        margin-top: rem-calc(40) !important;
        @include tablet-large {
            margin-top: rem-calc(80) !important;
        }
    }
    &-100 {
        margin-top: rem-calc(50) !important;
        @include tablet-large {
            margin-top: rem-calc(100) !important;
        }
    }
    &-120 {
        margin-top: rem-calc(60) !important;
        @include tablet-large {
            margin-top: rem-calc(120) !important;
        }
    }
    &-140 {
        margin-top: rem-calc(70) !important;
        @include tablet-large {
            margin-top: rem-calc(140) !important;
        }
    }
    &-160 {
        margin-top: rem-calc(80) !important;
        @include tablet-large {
            margin-top: rem-calc(160) !important;
        }
    }
    &-180 {
        margin-top: rem-calc(90) !important;
        @include tablet-large {
            margin-top: rem-calc(180) !important;
        }
    }
    &-200 {
        margin-top: rem-calc(100) !important;
        @include tablet-large {
            margin-top: rem-calc(200) !important;
        }
    }
}
.pb {
    &-0 {
        margin-bottom: 0px !important;
    }
    &-20 {
        margin-bottom: rem-calc(10) !important;
        @include tablet-large {
            margin-bottom: rem-calc(20) !important;
        }
    }
    &-40 {
        margin-bottom: rem-calc(20) !important;
        @include tablet-large {
            margin-bottom: rem-calc(40) !important;
        }
    }
    &-60 {
        margin-bottom: rem-calc(30) !important;
        @include tablet-large {
            margin-bottom: rem-calc(60) !important;
        }
    }
    &-80 {
        margin-bottom: rem-calc(40) !important;
        @include tablet-large {
            margin-bottom: rem-calc(80) !important;
        }
    }
    &-100 {
        margin-bottom: rem-calc(50) !important;
        @include tablet-large {
            margin-bottom: rem-calc(100) !important;
        }
    }
    &-120 {
        margin-bottom: rem-calc(60) !important;
        @include tablet-large {
            margin-bottom: rem-calc(120) !important;
        }
    }
    &-140 {
        margin-bottom: rem-calc(70) !important;
        @include tablet-large {
            margin-bottom: rem-calc(140) !important;
        }
    }
    &-160 {
        margin-bottom: rem-calc(80) !important;
        @include tablet-large {
            margin-bottom: rem-calc(160) !important;
        }
    }
    &-180 {
        margin-bottom: rem-calc(90) !important;
        @include tablet-large {
            margin-bottom: rem-calc(180) !important;
        }
    }
    &-200 {
        margin-bottom: rem-calc(100) !important;
        @include tablet-large {
            margin-bottom: rem-calc(200) !important;
        }
    }
}
.pl {
    &-0 {
        margin-left: 0px !important;
    }
    &-20 {
        margin-left: rem-calc(10) !important;
        @include tablet-large {
            margin-left: rem-calc(20) !important;
        }
    }
    &-40 {
        margin-left: rem-calc(20) !important;
        @include tablet-large {
            margin-left: rem-calc(40) !important;
        }
    }
    &-60 {
        margin-left: rem-calc(30) !important;
        @include tablet-large {
            margin-left: rem-calc(60) !important;
        }
    }
    &-80 {
        margin-left: rem-calc(40) !important;
        @include tablet-large {
            margin-left: rem-calc(80) !important;
        }
    }
    &-100 {
        margin-left: rem-calc(50) !important;
        @include tablet-large {
            margin-left: rem-calc(100) !important;
        }
    }
    &-120 {
        margin-left: rem-calc(60) !important;
        @include tablet-large {
            margin-left: rem-calc(120) !important;
        }
    }
    &-140 {
        margin-left: rem-calc(70) !important;
        @include tablet-large {
            margin-left: rem-calc(140) !important;
        }
    }
    &-160 {
        margin-left: rem-calc(80) !important;
        @include tablet-large {
            margin-left: rem-calc(160) !important;
        }
    }
    &-180 {
        margin-left: rem-calc(90) !important;
        @include tablet-large {
            margin-left: rem-calc(180) !important;
        }
    }
    &-200 {
        margin-left: rem-calc(100) !important;
        @include tablet-large {
            margin-left: rem-calc(200) !important;
        }
    }
}
.pr {
    &-0 {
        margin-right: 0px !important;
    }
    &-20 {
        margin-right: rem-calc(10) !important;
        @include tablet-large {
            margin-right: rem-calc(20) !important;
        }
    }
    &-40 {
        margin-right: rem-calc(20) !important;
        @include tablet-large {
            margin-right: rem-calc(40) !important;
        }
    }
    &-60 {
        margin-right: rem-calc(30) !important;
        @include tablet-large {
            margin-right: rem-calc(60) !important;
        }
    }
    &-80 {
        margin-right: rem-calc(40) !important;
        @include tablet-large {
            margin-right: rem-calc(80) !important;
        }
    }
    &-100 {
        margin-right: rem-calc(50) !important;
        @include tablet-large {
            margin-right: rem-calc(100) !important;
        }
    }
    &-120 {
        margin-right: rem-calc(60) !important;
        @include tablet-large {
            margin-right: rem-calc(120) !important;
        }
    }
    &-140 {
        margin-right: rem-calc(70) !important;
        @include tablet-large {
            margin-right: rem-calc(140) !important;
        }
    }
    &-160 {
        margin-right: rem-calc(80) !important;
        @include tablet-large {
            margin-right: rem-calc(160) !important;
        }
    }
    &-180 {
        margin-right: rem-calc(90) !important;
        @include tablet-large {
            margin-right: rem-calc(180) !important;
        }
    }
    &-200 {
        margin-right: rem-calc(100) !important;
        @include tablet-large {
            margin-right: rem-calc(200) !important;
        }
    }
}

// ====================================================================
//
// GENERAL HTML STYLING
//
// ====================================================================


// PLEASE NOTE: We are using Normalize rather than a CSS reset. It can come from Foundation or just itself when not using Fondation.
// See source/css/setup/libraries/_libraries-assembled.scss


// Globally set sizing to be border-box so widths and heights include padding and borders
html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

body {
    @extend %text-base;
    margin: 0;
} 


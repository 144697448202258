
#footer-page {
    clear: both;
    background-color: #c4d3e0;
    padding: 30px 20px 35px;

    & h5 {
        margin-bottom: 1em;
        font-size: rem-calc(15);
        line-height: 1.33333333;
        text-transform: uppercase;
    }

    & .four-column-group {
        display: flex;
        gap: 30px;
    }

    & .column {
        width: 25%;
        font-size: rem-calc(16);
        line-height: 1.3125;
        p {
            margin: .5em;
            margin-left: rem-calc(10);
            text-indent: rem-calc(-10);
        }
        & a {
            font-weight: 600;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    & #footer-columns {
        &-wrap {
            display: none; 
            @include tablet-large {
                display: flex;
                margin-bottom: rem-calc(20);
                padding-bottom: rem-calc(30);
                border-bottom: $border-base;
                border-color: $gray-light;
            }
        }
    }

    & .button.button--small {
        color: white;
        text-decoration: none;
        font-family: $headline-base-font-family;
        font-size: 15px;
        line-height: 20px;
        display: inline-block;
        padding: 5px 10px;
        background-color: $color-primary;
        border-radius: 5px;
    }

    // & #footer-columns {
    //     &-wrap {
    //         display: none; 
    //         @include tablet-large {
    //             display: block;
    //         }
    //     }
        // &-inner-wrap {
        //     flex-grow: 2;
        // }   
    // }

    & #footer-contact {
        flex-basis: 24%;
        min-width: 220px;
        max-width: 340px;
        padding-left: rem-calc(20);
        border-left: $border-base;
        border-color: $gray-light;
        font-weight: 600;
        font-size: rem-calc(16);
        line-height: 1.3125;
    }

    & .bottom-cap {
        @include phone-large {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    & .footer-id {
        display: block;
        width: rem-calc(250);
        height: rem-calc(70px);
        background-image: url(/typo3conf/ext/bronx_skin/Resources/Public/Images/Logos/BronxCare_newlogo_footer.png);
        background-repeat: no-repeat;
        background-position: left top;
        background-size: contain;
        text-indent: rem-calc(-99999px);
    }

    & .copyright {
        margin-top: rem-calc(30);
        font-size: rem-calc(15);
        line-height: rem-calc(18);
        @include phone-large {
            margin-top: 0;
            padding-left: rem-calc(40);
        }
        & > a {
            @extend .reset-link;
            &:hover {
                text-decoration: underline   ;
            }
        }
    }
}
//   #footer-page a {
//     font-weight: 400;
//   }
//   #footer-page h5 {
//     font-size: 12px;
//     line-height: 20px;
//     margin: 0 0 9px;
//     color: #155085;
//     text-transform: uppercase;
//   }
//   #footer-page #footer-columns-inner-wrap {
//     width: 680px;
//     float: left;
//     padding-bottom: 10px;
//   }
//   #footer-page #footer-columns-inner-wrap p {
//     font-size: 13px;
//     line-height: 15px;
//     margin-bottom: 7px;
//   }
//   #footer-page #footer-columns-inner-wrap p a {
//     color: #4D4D4D;
//     font-weight: 400;
//   }
//   #footer-page #footer-contact {
//     width: 215px;
//     float: right;
//     padding-left: 20px;
//     border-left: 1px solid #a1b1c4;
//     margin-bottom: 20px;
//   }
//   #footer-page #footer-contact p {
//     font-weight: bold;
//     color: #155085;
//     font-size: 12px;
//   }
//   #footer-page .bottom-cap {
//     clear: both;
//     position: relative;
//     border-top: 1px solid #a1b1c4;
//     padding-top: 20px;
//   }
//   #footer-page .bottom-cap .footer-id {
//     display: block;
//     width: 276px;
//     height: 31px;
//     background: transparent url(../Images/BronxLebanonFooterLogo.png) no-repeat left top;
//     overflow: hidden;
//     margin: 0;
//   }
//   #footer-page .bottom-cap .footer-id:hover {
//     border-bottom: none;
//   }
//   #footer-page .bottom-cap .footer-id span {
//     display: block;
//     padding-top: 100px;
//   }
//   #footer-page .bottom-cap .copyright {
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     font-size: 11px;
//   }
//   #footer-page .bottom-cap .copyright a {
//     color: #4D4D4D;
//   }
  
//   #copyright {
//     float: left;
//   }
  
//   #creator-link {
//     float: right;
//   }
// WCF Status
// Not ready for WCF. @TODO-RON Ron will likely be the one to edit.

a {
    color: $color-primary;
    font-weight: 700;
    text-decoration: underline;
    &:hover {
        color: $color-link-hover;
    }
}

.reset-link {

    &,
    &:hover,
    &:focus,
    &:active,
    &:hover,
    &:focus,
    &:active,
    &:visited
    &:visited:hover,
    &:visited:focus,
    &:visited:active {
        font-weight: normal;
        color: inherit;
        text-decoration: none;
        outline: 0;

        // @todo-ron is this neeeded?
        img {
            border: 0;
        }

        &:focus-visible{
            border: none;
            outline: 3px solid $color-primary;
        }
    }
}


// button {
//     background-color: $gray-95;
// }

// .btg {
//     &-button-group {
//         // margin: 30 30px;
//         // // padding: 30px 0;
//         font-size: 18px;
//         font-weight: bold;
//         text-transform: uppercase;
//     }
//     &--position {
//         &-left {
//             text-align: left;
//         }
//         &-right {
//             text-align: right;
//         }
//         &-center {
//             text-align: center;
//         }
//     }
    
//     &__inner-wrap {
//         // &.button-position {
//         //     &--right {
//         //         text-align: right;
//         //     }
//         //     &--center {
//         //         text-align: center;
//         //     }
//         // }

//         // padding: 0 !important;
//     }
//     &--center {
//         text-align: center;
//     }
//     &__link {
//         margin: 0 7px;
//         color: $site-white;
//         background-color: $color-secondary;
//         border-radius: 40px;
//         padding: .8em 3em;
//         font-size: 15px;
//         text-decoration: none;
//         // font-size: 17px;
//         // font-weight: bold;
//         // line-height: 4em;
//         display: inline-block;
//         white-space: nowrap;

//         &:first-child {
//             margin-left: 0;
//         }

//         &:last-child {
//             margin-right: 0;
//         }
    
//         &.solid-blue {
//             background-color: $color-primary;
//         }
    
//         &.outline-dark {
//             color: $site-black;
//             border: 1px solid $site-black;
//             background-color: transparent;
//         }
    
//         &.outline-light {
//             color: $site-white;
//             border: 1px solid $site-white;
//             background-color: transparent;
//         }
//         .btg--size-small & {
//             font-size: 1em;
//         }
//         .btg--size-medium & {
//             font-size: 1.1em;
//         }
//         .btg--size-large & {
//             font-size: 1.15em;
//         }
//     }
// }

// a {
//     color: $color-primary;
//     text-decoration: none;
// }


// /*

// %reset-anchor-base {
//     color: inherit;
//     text-decoration: none;
//     outline: 0;

//     // @todo-ron is this neeeded?
//     img {
//         border: 0;
//     }

//     &:focus-visible{
//         border: none;
//         outline: 3px solid $color-stone;
//     }
// }

// a, .reset-anchor {

//     &,
//     &:hover,
//     &:focus,
//     &:active,
//     &:hover,
//     &:focus,
//     &:active,
//     &:visited
//     &:visited:hover,
//     &:visited:focus,
//     &:visited:active {
//         @extend %reset-anchor-base;
//     }

//     // &:visited {
//     //     @extend %reset-anchor-base;

//     //     &:hover,
//     //     &:focus,
//     //     &:active {
//     //         @extend %reset-anchor-base;
//     //     }
//     // }
//     // &:hover, &:visited:hover {
//     //     color: inherit;
//     // }
// }


// .body-text a, .standard-link, %standard-link, .cke_editable a {

//     color: $color-red;

//     &:hover,
//     &:focus,
//     &:active {
//         text-decoration: underline;
//     }

//     &:visited {
//         color: $color-red;

//         &:hover,
//         &:focus,
//         &:active {
//             text-decoration: underline;
//         }
//     }

//     &:hover, &:visited:hover {
//         text-decoration: underline;
//     }

// }

// .body-text.body-text.invert-text a, .body-text.invert-text a:visited {
//     color: $color-stone;
//     text-decoration: underline;

//     &:hover,
//     &:focus,
//     &:active {
//         color: inherit;
//     }

//     &:focus-visible{
//         border: none;
//         outline: 3px solid $color-stone;
//     }
// }


// a {
//     h1, h2, h3, h4, h5, h6 {
//         &:hover, &:visited:hover {
//             color: var(--theme-color-2);
//         }
//     }
// }


// button,
// .button,
// a.button,
// .body-text button,
// .body-text .button,
// .body-text a.button {
//     display: inline-block;
//     margin-bottom: 10px;
//     padding: 1em 2em;
//     background-color: $color-red;
//     border: 1px solid $color-red;
//     color: $color-stone;
//     // font-family: $secondary-font-family;
//     font-weight: bold;
//     font-size: 12px;
//     text-align: center;
//     letter-spacing: .11667em;
//     line-height: 1.3;
//     text-transform: uppercase;
//     cursor: pointer;

//     &:hover,
//     &:focus,
//     &:active,
//     &:visited:hover,
//     &:visited:focus,
//     &:visited:active {
//         text-decoration: none;
//         background-color: $color-red-dark;
//         border: 1px solid $color-red-dark;
//         color: $color-stone;
//     }

//     &:focus-visible{
//         border: none;
//         outline: 3px solid $color-stone;
//         background-color: $color-red;
//     }

//     // &:visited {
//     //     &:hover,
//     //     &:focus,
//     //     &:active {
//     //         background-color: $color-red-dark;
//     //         border: 1px solid $color-red-dark;
//     //         color: $color-stone;
//     //     }
//     // }

//     &--blue {
//         background-color: $color-blue;
//         border: 1px solid $color-blue;

//         &:hover,
//         &:focus,
//         &:active,
//         &:visited:hover,
//         &:visited:focus,
//         &:visited:active {
//             background-color: $color-blue-dark;
//             border: 1px solid $color-blue-dark;
//         }

//         &.button--hollow {
//             color: $color-blue;

//             &:hover,
//             &:focus,
//             &:active,
//             &:visited:hover,
//             &:visited:focus,
//             &:visited:active {
//                 color: $color-stone;
//             }


//             &:focus-visible{
//                 border: none;
//                 outline: 3px solid $color-stone;
//                 color: $color-blue-dark;
//             }
//         }


//         &:focus-visible{
//             border: none;
//             outline: 3px solid $color-stone;
//             background-color: $color-blue-dark;
//         }
//     }

//     &--gray {
//         background: $color-warm-gray;
//         border-color: $color-warm-gray;

//         &:hover,
//         &:focus,
//         &:active,
//         &:visited:hover,
//         &:visited:focus,
//         &:visited:active {
//             background-color: $color-warm-gray-dark;
//             border: 1px solid $color-warm-gray-dark;
//         }


//         &:focus-visible{
//             border: none;
//             outline: 3px solid $color-stone;
//             background-color: $color-warm-gray-dark;
//         }

//         &-hollow {
//             border: 1px solid $color-warm-gray;
//             color: $color-warm-gray-dark;
//             background: transparent;

//             &:hover,
//             &:focus,
//             &:active,
//             &:visited:hover,
//             &:visited:focus,
//             &:visited:active {
//                 border-color: $color-warm-gray-dark;
//                 color: $color-warm-gray-dark;
//                 background: transparent;
//             }

//             &:focus-visible{
//                 border: none;
//                 outline: 3px solid $color-warm-gray-dark;
//             }
//         }

//     }

//     &--stone.button--hollow {
//         background-color: transparent;
//         border: 1px solid $color-stone;
//         color: $color-stone;

//         &:hover,
//         &:focus,
//         &:active,
//         &:visited:hover,
//         &:visited:focus,
//         &:visited:active {
//             background-color: $color-stone;
//             color: $color-blue-dark;
//         }

//         &:focus-visible{
//             border: none;
//             outline: 3px solid $color-stone;
//         }
//     }

//     &--large {
//         padding: 1.05em 2.5em;
//         // font-family: $secondary-font-family;
//         font-size: 16px;
//         &:focus-visible{
//             border: none;
//             outline: 3px solid $color-stone;
//         }
//     }

//     &--small {
//         padding: .9em 1em;
//         // font-family: $secondary-font-family;
//         font-size: 10px;

//         &:focus-visible{
//             border: none;
//             outline: 3px solid $color-stone;
//         }
//     }

//     &--hollow {
//         background-color: transparent;
//         color: $color-red;

//         &:focus-visible{
//             border: none;
//             outline: 3px solid $color-red;
//         }
//     }

//     &--hollow-invert {
//         background-color: transparent;
//         color: $color-stone;
//         border-color: $color-stone;

//         &:hover {
//             background-color: rgba(0,0,0,0.2) !important;
//             border-color: $color-stone;
//         }

//         &:focus-visible{
//             border: none;
//             outline: 3px solid $color-stone;
//             background-color: rgba(0,0,0,0.2) !important;
//         }
//     }
// }

// // .body-text a.button {
// //     display: block;
// // }


// // .body-text a, .standard-link, %standard-link {
// //     &,
// //     &:hover,
// //     &:focus,
// //     &:active {
// //         @extend %anchor-base;
// //     }

// //     &:visited {
// //         @extend %anchor-base;

// //         &:hover,
// //         &:focus,
// //         &:active {
// //             @extend %anchor-base;
// //         }
// //     }

// //     &:hover, &:visited:hover {
// //         text-decoration: underline;
// //     }
// // }





// // @TODO-RON Show Alex how I handle general link styling

// // placeholder to reset anchor tag
// // %anchor-base {
// //     color: var(--theme-color-link);
// //     outline: 0;

// //     // @todo-ron is this neeeded?
// //     img {
// //         border: 0;
// //     }
// // }



// // a, .reset-anchor {

// //     &,
// //     &:hover,
// //     &:focus,
// //     &:active {
// //         @extend %reset-anchor-base;
// //     }

// //     &:visited {
// //         @extend %reset-anchor-base;

// //         &:hover,
// //         &:focus,
// //         &:active {
// //             @extend %reset-anchor-base;
// //         }
// //     }
// //     &:hover, &:visited:hover {
// //         color: inherit;
// //     }
// // }


// // .body-text a, .standard-link, %standard-link {
// //     &,
// //     &:hover,
// //     &:focus,
// //     &:active {
// //         @extend %anchor-base;
// //     }

// //     &:visited {
// //         @extend %anchor-base;

// //         &:hover,
// //         &:focus,
// //         &:active {
// //             @extend %anchor-base;
// //         }
// //     }

// //     &:hover, &:visited:hover {
// //         text-decoration: underline;
// //     }
// // }

// // a {
// //     h1, h2, h3, h4, h5, h6 {
// //         &:hover, &:visited:hover {
// //             color: var(--theme-color-2);
// //         }
// //     }
// // }



// // .thin-arrow-link {

// //     margin: 30px 0 0;
// //     text-align: center;
// //     color: var(--theme-color-4);
// //     text-decoration: none;
// //     text-transform: uppercase;
// //     letter-spacing: 4px;
// //     cursor: pointer;

// // }

// // .big-button, %big-button {
// //     display: inline-block;
// //     margin-top:20px;
// //     color: white !important;
// //     background-color:  var(--theme-color-5);
// //     padding: 15px 25px;
// //     font-size: 16px;
// //     border: none;
// //     text-transform: uppercase;
// //     letter-spacing: 1px;
// //     cursor: pointer;

// //     @include breakpoint(medium) {
// //         font-size: 18px;
// //     }
// //     &:hover {
// //         background-color: var(--theme-color-6);
// //     }
// // }

// //     @include breakpoint(small-2 down) {
// //         margin-top: 15px;
// //         letter-spacing: 3px;
// //     }
// //
// //     .general-teaser--vertical & {
// //         padding: 25px 50px;
// //         background-color: var(--theme-color-2);
// //         text-align: center;
// //         color: white;
// //         font-size: 16px;
// //
// //         @include breakpoint(small-2 down) {
// //             padding: 10px 20px;
// //             font-size: 14px;
// //         }
// //
// //         .thin-right-arrow {
// //             fill: white;
// //         }
// //     }
// // }

// */


.local-menu {
    width: rem-calc(400);
    max-width: 100%;
    margin: 0 auto;
    border: 3px solid $color-bg-section;
    @include tablet-large {
        width: 100%;
        padding: 0;
        border: none;
    }
    &__title-mobile {
        position: relative;
        padding: rem-calc(15) rem-calc(20) rem-calc(12);
        color: $color-secondary;
        font-size: rem-calc(18);
        font-family: $headline-base-font-family;
        font-weight: bold;
        line-height: 0 rem-calc(20);
        cursor: pointer;
        // &:after {
        //     content: "\2039";
        //     position: absolute;
        //     top: rem-calc(27)
        //     right: rem-calc(20);
        //     font-size: rem-calc(30);
        //     line-height: 0;
        //     top: 27px;
        //     transform: rotate(90deg);
        // }
        @include tablet-large {
            display: none;
        }
    }
    &__status {
        position: absolute;
        top: rem-calc(29);
        right: rem-calc(20);
        font-size: rem-calc(35);
        line-height: 0;
        top: 27px;
        transform: rotate(270deg);
        &--open {
            transform: rotate(90deg);
        }
    }
    &__inner-wrap {
        display: none;
        padding: 0 rem-calc(20) rem-calc(30);
        @include media($until:"tablet-large") {
            &.open {
                display: block;
            }
        }
        @include tablet-large {
            display: block;
            padding: 0;
        }
    }
    &__home-link {
        @extend .headline-xs;
        display: block;
        margin-top: 0;
        // border-top: $border-base;
        // border-top-color: $color-accent-blue-gray;
        border-top: 2px $color-accent-blue-gray solid;
        padding-top: rem-calc(20);
        // margin: 0;
        color: $color-text;
        font-size: rem-calc(19);
        // font-size: rem-calc(20);
        // font-weight: 600;
        // line-height: rem-calc(32);
        text-decoration: none;
        &:hover {
            color: $color-secondary;
        }
        @include tablet-large {
            margin-top: 0;
            padding-top: 0;
            border: none;
            font-size: rem-calc(20);
        }
    }
    &__list {
        @extend .no-bullets;
        margin: 0;
        font-size: rem-calc(18);
        font-family: $headline-base-font-family;
        line-height: rem-calc(20);
        &-item {
            position: relative;
            padding-left: 10px;
            border-bottom: $border-base;
            border-bottom-color: $color-accent-blue-gray;
            &:first-child {
                border-top: $border-base;
                border-top-color: $color-accent-blue-gray;
            }
            &.current {
                // list-style: disc;
                // list-style-position: inside;
                // &::marker {
                //     position: absolute;
                //     left: 0;
                //     margin: 0;
                // }
                &::before {
                    content: '\2022';
                    position: absolute;
                    top: rem-calc(5);
                    left: 0;
                    color: $color-secondary;
                }
            }
        }
        &-level-2 {
            @extend .no-bullets;
            margin: 0;
            padding-left: rem-calc(10);
            font-size: rem-calc(18);
            line-height: rem-calc(20);
        }
    }
    &__link {
        // @extend .reset-link;
        // background-color: blue;
        display: block;
        padding: rem-calc(7) 0;
        color: $color-primary;
        text-decoration: none;
        .local-menu__list-item.current > &, .local-menu__list-item-level-2.current > &, &:hover {
            color: $color-secondary;
        }
        // .local-menu__list-item.current &

    }
}
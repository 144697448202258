// WCF Status
// Not ready for WCF. May or may not be used.

// TEXT COLORS

.font {
    &-proxima {
        font-family: $headline-base-font-family;
    }
    &-proxima-bold {
        font-family: $headline-base-font-family;
        font-weight: 700;
    }
    &-proxima-semibold {
        font-family: $headline-base-font-family;
        font-weight: 600;
    }
}

.text-color {
    &--site-default {
        color: $color-text;
    }
    &--primary {
        color: $color-primary;
    }
    &--secondary {
        color: $color-secondary;
    }
    &--tertiary {
        color: $color-tertiary;
    }
    &--success {
        color: $color-success;
    }
    &--warning {
        color: $color-warning;
    }
    &--error {
        color: $color-error;
    }
    &--placeholder {
        color: $color-placeholder;
    }
    // Often we may use project friendly names like "red"

    // Site Grays
    &--gray {
        color: $gray;
        &-light {
            color: $gray-light;
        }
        &-lighter {
            color: $gray-lighter;
        }
        &-lightest {
            color: $gray-lightest;
        }
        &-dark {
            color: $gray-dark;
        }
        &-darker {
            color: $gray-darker;
        }
        &-darkest {
            color: $gray-darkest;
        }
     // Grayscale
        &-05 {
            color: $gray-05;
        }
        &-10 {
            color: $gray-10;
        }
        &-15 {
            color: $gray-15;
        }
        &-20 {
            color: $gray-20;
        }
        &-25 {
            color: $gray-25;
        }
        &-30 {
            color: $gray-30;
        }
        &-35 {
            color: $gray-35;
        }
        &-40 {
            color: $gray-40;
        }
        &-45 {
            color: $gray-45;
        }
        &-50 {
            color: $gray-50;
        }
        &-55 {
            color: $gray-55;
        }
        &-60 {
            color: $gray-60;
        }
        &-65 {
            color: $gray-65;
        }
        &-70 {
            color: $gray-70;
        }
        &-75 {
            color: $gray-75;
        }
        &-80 {
            color: $gray-80;
        }
        &-85 {
            color: $gray-85;
        }
        &-90 {
            color: $gray-90;
        }
        &-95 {
            color: $gray-95;
        }
    }
}


// BACKGROUND COLORS

.bg-color {
    &--primary {
        background-color: $color-primary;
    }
    &--secondary {
        background-color: $color-secondary;
    }
    &--tertiary {
        background-color: $color-tertiary;
    }
    &--success {
        background-color: $color-success;
    }
    &--warning {
        background-color: $color-warning;
    }
    &--error {
        background-color: $color-error;
    }
    &--placeholder {
        background-color: $color-placeholder;
    }
    &--site-black {
        background-color: $site-black;
    }
    &--site-white {
        background-color: $site-white;
    }
    &--section {
        background-color: $color-bg-section;
    }
    // Often we may use project friendly names like "red"

    // Site Grays
    &--gray {
        background-color: $gray;
        &-light {
            background-color: $gray-light;
        }
        &-lighter {
            background-color: $gray-lighter;
        }
        &-lightest {
            background-color: $gray-lightest;
        }
        &-dark {
            background-color: $gray-dark;
        }
        &-darker {
            background-color: $gray-darker;
        }
        &-darkest {
            background-color: $gray-darkest;
        }
     // Grayscale
        &-05 {
            background-color: $gray-05;
        }
        &-10 {
            background-color: $gray-10;
        }
        &-15 {
            background-color: $gray-15;
        }
        &-20 {
            background-color: $gray-20;
        }
        &-25 {
            background-color: $gray-25;
        }
        &-30 {
            background-color: $gray-30;
        }
        &-35 {
            background-color: $gray-35;
        }
        &-40 {
            background-color: $gray-40;
        }
        &-45 {
            background-color: $gray-45;
        }
        &-50 {
            background-color: $gray-50;
        }
        &-55 {
            background-color: $gray-55;
        }
        &-60 {
            background-color: $gray-60;
        }
        &-65 {
            background-color: $gray-65;
        }
        &-70 {
            background-color: $gray-70;
        }
        &-75 {
            background-color: $gray-75;
        }
        &-80 {
            background-color: $gray-80;
        }
        &-85 {
            background-color: $gray-85;
        }
        &-90 {
            background-color: $gray-90;
        }
        &-95 {
            background-color: $gray-95;
        }
    }
}

/* Completely remove from the flow and screen readers. */
.is-hidden {
    display: none !important;
    visibility: hidden !important;
}

.ce-inner-wrap.ce--bleed {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}


.video-teaser {


    &__info {
        //background-color: var(--theme-color-2);
        padding: 10px 15px;
        color: white;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    &__overlay{
       /* background: linear-gradient(
                to top,
                #242424,
                rgba(255, 0, 0, 0)
        );
        position: absolute;
        top: 0;
        height: 100%;
        */
        background: linear-gradient(
                to top,
                #000000,
                rgba(0, 0, 0, 0)
        );
        position: absolute;
        bottom: 0;
        height: 25%;
        width: 100%;
    }
    &__title {
        float: left;

    }
    &__time {
        float: right;
    }

    // @TODO-RON need to get class on local video to be more easily targeted

    &__video,
    video {
        display: none;
        // border: 1px solid green;
    }

    &__video-wrap {
        position: relative;
    }

    &__play-arrow {
        background: transparent url(/typo3conf/ext/bn_contentelements/res/video/images/play_button.png) no-repeat scroll left top;
        height: 31px;
        left: 50%;
        margin-left: -16px;
        margin-top: -16px;
        position: absolute;
        top: 50%;
        width: 31px;

    }



}


.header-page {
        // height: 155px;
    // margin-bottom: 35px;
    z-index: 100;
    position: relative;
    // background-color: white;

    @include media ($until: tablet-large) {
      // display: none;
      height: 200px;
    }
    &__identity {
        display: block;
        float: left;
        width: 350px;
        height: 110px;
        margin: 25px 0 10px 20px;
        background-image: url(/typo3conf/ext/bronx_skin/Resources/Public/Images/Logos/bronx-care.png);
        background-size: contain;
        background-repeat: no-repeat;
        overflow: hidden;
        text-indent: -9999px;
        @include media ($until: tablet-small) {
            width: 240px;
            max-width: 100%;
            margin-top: 100px;
          }
      
        @include media ($from: tablet-small, $until: tablet-large) {
            width: 350px;
            max-width: 100%;
            margin-top: 80px;
          }
    }
    &__utility-nav {
        list-style: none;
        float: right;
        max-width: auto;
        margin: 20px 27px 23px 16px;
        font-size: rem-calc(17);
        line-height: 1.29411765;
        text-align: right;
        // @include media ($until: tablet-small) {
        //   max-width: 350px;
        // }
        @include media ($until: tablet-large) {
            position: absolute;
            top: 15px;
            right: 15px;
            margin: 0;
            padding: 0;
            font-size: rem-calc(14);
        }
        &-item {
            display: inline-block;
            // float: left;
            // font-size: 13px;
            // line-height: 13px;
            margin-left: 13px;
            padding-left: 13px;
            margin-bottom: 5px;
            border-left: 1px solid #c4d3e0;
            // text-align: right;
            &:first-child, &:nth-child(2) {
              display: none;
              border-left: none;
            }
        
            &:nth-child(3) {
              border-left: none;
            }

            &:last-child {
                padding-right: 0;
                margin-right: 0;
                border-right: none;
            
            }
            
            @include media ($until: tablet-large) {
              // margin: 0;
              // padding: 0;
              // border: none;
            }
        }
        &-link {
            @extend .reset-link;
            &:hover {
                text-decoration: underline;
            }
            color: $color-link;
            font-weight: 400;
        }
    }
    &__search {
        float: right;
        clear: right;
        position: relative;
        overflow: hidden;
        @include media ($until: tablet-large) {
          position: absolute;
          top: 70px;
          right: 15px ;
        }
        label {
            position: absolute;
            left: -9999px;
          }
        input {
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            border-radius: 5px;
            width: 260px;
            margin-top: 19px;
            margin-right: 26px;
            padding: 6px 30px 6px 10px;
            // background: transparent url(/typo3conf/ext/bronx_skin/Resources/Public/Images/SearchGlass.png) no-repeat right 3px;
            border: 1px solid #c4d3e0;
            @include media ($until: tablet-large) {
            width: rem-calc(170);
            margin: 0;
            }
        }
    }

    &__mobile-trigger {
        display: none;
        // @include media ($until: tablet-large) {
        //   margin-top: ;
        // }
        @include media ($until: tablet-large) {
          position: relative;
          float: right;
          margin: 130px 10px 0 0px;
          display: inline-block;
          padding: 10px;
          box-sizing: border-box;
          // border-right: none;
          background-color: white;
          z-index: 100;
          &.open {
            border: 1px solid $color-menu-blue-light;
            border-bottom: none;
          }
        }
        &-line {
            width: 28px;
            height: 4px;
            background-color: #155085;
            display: block;
            margin: 4px 0;
            transition: 0.4s;
            position: relative;
            z-index: 20;
        }
    }


    // @TODO-RON DO NEXT HIV SITE LINE 185

}

@include media ($until: tablet-large) {
  .gn {
      &-global-navigation {
          display: none;
          position: absolute;
          right: 0;
          top: 178px;
          width: 100%;
          max-width: rem-calc(400);
          padding: rem-calc(30);
          background-color: white;
          border: 1px solid $color-menu-blue-light;
          border-right: none;
          &.open {
          display: block;
          }
          & a {
              // display: block;
              // position: relative;
              padding-right: rem-calc(20);
              font-weight: 600;
              text-decoration: none;
          }
          & ul {
              // padding: 0;
              list-style: none;
          }
      }
      &__submenu-trigger {
          float: right;
          cursor: pointer;
          &.open {
              transform: rotate(180deg);
              color: $color-secondary;
          }
      }
      &__level-1 {
          margin: 0;
          padding-left: 0;
          &-link {
            font-family: $headline-base-font-family;
          }
      }
      &__submenu {
          padding-left: rem-calc(20);
          &-wrap {
              display: none;
              &.open {
                  display: block;
              }
              & a {
                font-family: $font-family-base;
              }
          }
          
          &-level-2   {
              display: none;
              padding-left: rem-calc(20);
              &.open {
                  display: block;
              }
          }
          &-column {
              margin-bottom: 0;
              padding-left: 0;
          }
      }
  }
}

@include tablet-large {
.gn {
  &-global-navigation {
    display: block;
    clear: both;
    background-color: $color-primary;
    color: white;
    font-family: $headline-base-font-family;
    font-size: rem-calc(18);
    line-height: 1.66666667;
  }
  &__submenu-trigger {
      display: none;
  }
  &__level-1 {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-content: stretch;
    margin: 0;
    padding: 0;
    list-style: none;
    &-item {
      position: relative;
      flex-grow: 1;
      text-align: center;
      border: rem-calc(1) solid $color-primary;
      border-bottom-width: 0;
      &:hover {
        background-color: $color-menu-blue-light;
        border-color: $color-menu-blue-light;
        &.has-submenu {
          background-color: white;
          border: rem-calc(1) solid $color-secondary;
          // border-bottom-width: 0;
        }
        & > .gn__level-1-link {
          color: $color-primary;
          font-weight: 600;
        }
      }
      &.active {
        background-color: $color-menu-blue-light;
        border-color: $color-menu-blue-light;
        & > .gn__level-1-link {
          color: $color-primary;
          font-weight: 600;
        }
      }
      &.mega-menu {
        position: static;
      }
    }
    &-link {
      display: block;
      padding: rem-calc(7) rem-calc(6) rem-calc(6);
      color: $color-menu-blue-light;
      font-weight: normal;
      text-decoration: none;
    }
    
  }

  &__submenu {
    position: absolute;
    padding: 0;
    width: rem-calc(360);
    padding: rem-calc(25) rem-calc(30);
    border: rem-calc(1) solid $color-secondary;
    border-top: none;
    background-color: white;
    .gn__level-1-item:last-child &, .gn__level-1-item:nth-child(7) & {
      right: 0;
    }
    // .mega-menu 
    &--column-group {
      display: flex;
      left: 0;
      width: 100%;
      gap: 50px;
      justify-content: space-between;
    }
    &-column {
      padding: 0;
      flex-basis: 33%;
      padding-left: 20px;
      border-left: 1px solid $gray-line-light;
      &:first-child {
        border-left: none;
      }
    }
    &-wrap {
      // display: none;
      visibility: hidden;
      max-width: 100%;
      margin: 0;
      line-height: rem-calc(23);
      text-align: left;
      .gn__level-1-item:hover > & {
        // display: block;
        visibility: visible;
      }
      &:before {
        content: '';
        display: block;
        position: relative;
        top:3px;
        left: 1px;
        height: 3px;
        z-index: 50;
        background-color: white ;
      }
      & a {
        font-family: $font-family-base;
      }
    }
    &-item {
      margin-bottom: rem-calc(5);
    }
    &-level-2 {
      display: none;
      margin-bottom: rem-calc(5);
      padding-left: rem-calc(10);
      .mega-menu & {
        display: block;
      }
      &-item {
        // display: none;
        margin-bottom: rem-calc(5);
        line-height: rem-calc(23);
        &:first-child {
          margin-top: rem-calc(5);
        }
      }
    }
    & a {
      font-weight: 600;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
}